<template>
  <div class="main-content auth">
    <div class="auth-container box">
      <Switch :switchValue="switchValue" @switchValueChanged="switchValue = !switchValue"/>
      <img class="auth__logo" src="@/assets/images/logos/LogoMeaning.svg">
      <div class="auth__titles">
        <h2>{{$t('accessactivity.title')}}</h2>
        <p class="subtitle">{{$t('accessactivity.subtitle')}}</p>
      </div> 
      <div class="auth__form">
        <div class="auth__own">
          <form v-if="codeActivity" @submit.prevent="goToActivity()">
            <form-input v-model.trim="name" type="text" :required="true" :autofocus="true">
              {{$t('accessactivity.activityname')}}
            </form-input>
            <p class="is-code">{{$t('accessactivity.entername')}}</p>
            <div class="form__group form__group--code" :class="{'code-hidden':codeActivity}">
              <label class="form__label" for="">{{$t('accessactivity.activitycode')}}</label>
              <div class="form__code-inputs" >
                <input class="form__input" type="text" size="1" @input="updateCharAt(0, $event)" :value="code[0]" :ref="'char-0'" :readonly="codeFromQuery">
                <input class="form__input" type="text" size="1" @input="updateCharAt(1, $event)" :value="code[1]" :ref="'char-1'" :readonly="codeFromQuery">
                <input class="form__input" type="text" size="1" @input="updateCharAt(2, $event)" :value="code[2]" :ref="'char-2'" :readonly="codeFromQuery">
                <input class="form__input" type="text" size="1" @input="updateCharAt(3, $event)" :value="code[3]" :ref="'char-3'" :readonly="codeFromQuery">
                <input class="form__input" type="text" size="1" @input="updateCharAt(4, $event)" :value="code[4]" :ref="'char-4'" :readonly="codeFromQuery">
              </div>
            </div>
            <!--form-input v-model="myCode" type="text" :required="true" :minlength="5" :maxlength="5" :readonly="$route.query.code">
              Código
            </form-input-->
            <div class="form__actions">
              <button class="btn btn--primary btn--block" type="submit" :disabled="!name || !myCode || myCode.length !== 5">{{$t('accessactivity.enteractivity')}}</button>
            </div>
          </form>
          <form v-else @submit.prevent="goToActivityNoCode()">
            <form-input  class="centrar" v-model.trim="name" type="text" :required="true" :autofocus="true">
              {{$t('accessactivity.activityname')}}
            </form-input>
            <div class="form__group form__group--code">
              <label  :autofocus="true" class="form__label" for="">{{$t('accessactivity.activitycode')}}</label>
              <div class="form__code-inputs">
                <input class="form__input" type="text" size="1" :class="{'no-code':!codeActivity}" v-model="nourlcode"  maxlength="5">
              </div>
            </div>
            <!--form-input v-model="myCode" type="text" :required="true" :minlength="5" :maxlength="5" :readonly="$route.query.code">
              Código
            </form-input-->
            <div class="form__actions">
              <button class="btn btn--primary btn--block" type="submit" :disabled="!name || nourlcode.length !== 5">{{$t('accessactivity.enteractivity')}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <modal v-if="showAlertModal" @close="showAlertModal = false">
    <template #title>{{$t('accessactivity.codnodis')}}</template>
    <template #content>
      <p>{{$t('accessactivity.caducatecode')}}</p>
    </template>
    <template #footer>
      <button type="button" class="btn btn--primary btn--outline" @click.prevent="showAlertModal = false">{{$t('accessactivity.entendido')}}</button>
    </template>
  </modal>
</template>

<script>
import Switch from '../components/Switch.vue'
import { inject } from 'vue'
import FormInput from '@/components/FormInput'
import Modal from '@/components/Modal'
import { useRouter } from 'vue-router'

export default {
  name: 'AccessActivity',
  setup () {
    const route = useRouter().currentRoute.value
    const codeActivity = route.query.code
    const axios = inject('$axios')
    return { axios,codeActivity}
  },
  data () {
    return {
      name: null,
      code: [null,null,null,null,null],
      nourlcode: "",
      showAlertModal: false,
       switchValue: true
    }
  },
  components: { FormInput, Modal,Switch },
  methods: {
    goToActivity() {
      this.axios.post('auth/check-code-validity/', { code: this.myCode }).then(res => {
        if (res.data.isValid) {
          this.$router.push({ name: 'AnswerActivity', params: { code: this.myCode }, query: { name: this.name } })
        } else {
          this.showAlertModal = true
        }
      })
    },
    goToActivityNoCode() {
      this.axios.post('auth/check-code-validity/', { code: this.nourlcode }).then(res => {
        if (res.data.isValid) {
          this.$router.push({ name: 'AnswerActivity', params: { code: this.nourlcode }, query: { name: this.name } })
        } else {
          this.showAlertModal = true
        }
      })
    },
    updateCharAt (index, ev) {
      const chars = '0123456789BCDFGHJKLMNPQRSTVWXYZ'
      if (ev.target.value) {
        const newChar = ev.target.value.split('').pop().toUpperCase()
        if (chars.split('').includes(newChar)) {
          this.code[index] = ev.target.value = newChar
          const nextInput = this.$refs[`char-${index + 1}`] || this.$refs[`char-${index}`]
          nextInput.focus()
        } else {
          this.code[index] = ev.target.value = ''
        }
      } else {
        this.code[index] = ''

        let adjacentInput = this.$refs[`char-${index - 1}`] || this.$refs[`char-${index}`]
        if (ev.inputType === 'deleteContentBackward') {
          adjacentInput = this.$refs[`char-${index - 1}`] || this.$refs[`char-${index}`]
        } else if (ev.inputType === 'deleteContentForward') {
          adjacentInput = this.$refs[`char-${index + 1}`] || this.$refs[`char-${index}`]
        }
        adjacentInput.focus()
      }
    },

  },
  computed: {
    myCode: {
      get () { return this.$route.query.code || this.code.join('') },
      set (val) { this.code = val.toUpperCase().split('') }
    },
    codeFromQuery () {
      return this.myCode === this.$route.query.code
    }
  },
  created () {
    //this.$refs.codeChars = []
  },
  mounted () {
    if (this.$route.query.code) {
      this.myCode = this.$route.query.code
    }
  }
}


</script>

<style>
.no-code{
  max-width: 100% !important;
}
.code-hidden{
  display: none !important;
}
.is-code{
  color: #008f39;
  font-family: "RobotoCondensed-Regular";
  font-size: 20px;
}

.layout--out .content-wrapper .main-content.auth .auth-container .auth__own .form__group{
  text-align: center;
}

.form__input{
  text-align: center;
}
</style>